import * as Sentry from '@sentry/vue'

import router from '../router'
import type { App, Plugin } from 'vue'

const { SENTRY_FRONTEND_DSN, SENTRY_ENV } = import.meta.env

const ENABLED_DOMAINS = [
  /^https:\/\/climatecontrolcenter\.com/,
  /^https:\/\/staging.climatecontrolcenter\.com/,
  /^https:\/\/center.greenzero\.eu/,
]

const isProdOrStaging = ['production', 'staging'].includes(SENTRY_ENV as string)

export const sentryPlugin: Plugin = {
  install(app: App) {
    if (isProdOrStaging) {
      Sentry.init({
        app,
        environment: SENTRY_ENV,
        dsn: SENTRY_FRONTEND_DSN,
        trackComponents: true,
        integrations: [
          Sentry.browserTracingIntegration({ router }),
          Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        // Learn more at
        // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: ENABLED_DOMAINS,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        // Learn more at
        // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      })
    }
  },
}

export function sentryErrorHandler(error: Error) {
  Sentry.captureException(error)
}
